import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
  production: true,
  baseUrl: '',
  cardholderBaseUrl: 'https://hd.et.vn.ua/cardholder',
  cardholderBaseLogin: '',
  cardholderBasePassword: '',
  zabbix: {
    zabbixUrl: 'https://monitoring.et.vn.ua/zabbix/api_jsonrpc.php',
    zabbixDefaultGroups: ['17', '18', '21', '22', '24', '25', '28', '34', '35', '37'],
    zabbixPrinterGroupName: 'Printer_position_1',
    octGroups: ['17', '22', '24', '28', '34'],
    cvbGroups: ['18', '21', '25', '35', '37'],
    key_Parameters: 'vfs.file.contents[/root/ConvRoute/ZABB,total]',
    key_ip: 'system.loc_ip',
    key_gps: 'system.run[\"timeout 5 gpspipe -w -n 7 | grep -m 1 lat\"]',
    key_reboot_oct: 'system.run[\"reboot\"]',
    key_reboot_cvb: 'system.run[\"reboot\"]',
    key_ping: 'agent.ping',
    key_uptime: 'system.uptime',
    key_event_close: 'system.run[\"> /root/ConvRoute/event_close\"]',
    key_event_upload: 'system.run[\">/root/ConvRoute/event_upload\"]',
    disablePrinter: false,
    disableGPS: true,
    depots: [
      {
        LEFT_TOP: {
          lat: 49.23433,
          lon: 28.434416
        },
        RIGHT_TOP: {
          lat: 49.234303,
          lon: 28.437416
        },
        LEFT_BOTTOM: {
          lat: 49.231644,
          lon: 28.435007
        },
        RIGHT_BOTTOM: {
          lat: 49.232304,
          lon: 28.437597
        }
      },
      {
        LEFT_TOP: {
          lat: 49.241055,
          lon: 28.455227
        },
        RIGHT_TOP: {
          lat: 49.24019,
          lon: 28.458626
        },
        LEFT_BOTTOM: {
          lat: 49.239777,
          lon: 28.454514
        },
        RIGHT_BOTTOM: {
          lat: 49.23895,
          lon: 28.458048
        }
      },
      {
        LEFT_TOP: {
          lat: 50.44696,
          lon: 30.512604
        },
        RIGHT_TOP: {
          lat: 50.44696,
          lon: 30.532604
        },
        LEFT_BOTTOM: {
          lat: 50.42696,
          lon: 30.512604
        },
        RIGHT_BOTTOM: {
          lat: 50.42696,
          lon: 30.532604
        }
      }
    ]
  },
  language: 'uk',
  logLevel: NgxLoggerLevel.ERROR,
  rowsInTable: 20,
  isDebugMode: false,
  countMonth: -12,
  countReportDaysDefore: 7,
  available_languages: ['uk', 'en'], // For ukrainian projects
  showLangIcon: false,
  version: '2.1.4',
  develop_company: 'SYMBOL TRANSPORT',
  develop_company_link: 'https://symboltransport.com/',
  // UAH RON
  report_currency: 'UAH',
  project: 'Вінниця'
};
